@import-normalize;

$white: #eee;
$brightWhite: #fff;

.text-center {
    text-align: center;
}

body {
    background: #333;
    color: $white;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
    text-align: center;
    font-weight: 900;
}

h1 {
    margin: 0 0 30px 0;
    font-size: 55px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 20px;
}

a {
    color: $white;
    text-decoration: underline;
}

p, li {
    line-height: 30px;

    a {
        transition: all 0.2s ease;
        position: relative;

        &:hover {
            color: $brightWhite;
            text-shadow: -3px 3px 2px rgba(0, 0, 0, 0.3);
            top: -1px;
        }
    }
}

ul {
    padding: 0;
}

header {
    padding: 60px;
}

header {
    background: #a00;
    color: $white;
    text-align: center;

    .logo {
        width: 90%;
        max-width: 200px;
        margin: 0 auto;
    }

    h1 {
        margin-bottom: 0;

        span {
            display: block;
            margin-top: 20px;
            font-size: 30px;
            font-weight: 100;
        }
    }
}

footer {
    padding: 20px 0;
    background: #a00;
    color: $white;
    width: 100%;
    text-align: center;

    a {
        font-size: 30px;
        margin: 20px;
        color: $white;
    }
}

.content {
    section {
        &:nth-child(even) {
            background: rgba(255, 255, 255, 0.1);
        }
    }
}

.home {
    padding: 60px 5%;
    text-align: center;

    max-width: 800px;
    margin: 0 auto;

    p {
        margin-top: 0;
    }

    .social-links {
        font-size: 30px;
        margin-bottom: 0;

        li {
            margin: 0 20px;
        }
    }
}

.blog {
    position: relative;

    h1,
    h2,
    h3 {
        text-align: center;
    }

    h1 {
        padding: 60px 0 30px;
    }

    .blog-back-button {
        text-decoration: none;
        transition: background-color 0.5s ease;
        background: #aa0000;
        padding: 10px 20px;

        &:before {
            content: '\00ab';
            font-size: 23px;
            margin-right: 5px;
        }

        &:hover {
            background: lighten(#aa0000, 5%);
        }
    }

    .blog-header {
        align-items: center;
        justify-content: center;

        .blog-title {
            padding: 60px 5%;
        }
    }
    
    .blog-content {
        padding: 60px 5%;

        max-width: 800px;
        margin: 0 auto;

        h2, h3 {
            margin: 40px 0;
        }

        code {
            padding: 5px;
            margin: 0 5px;
            background: rgba(255, 255, 255, 0.1);
        }

        pre, table, blockquote, ul, ol {
            margin: 20px 0;
            padding: 15px 20px;
            background: rgba(255, 255, 255, 0.1);
            white-space: normal;
        }

        pre {
            code {
                white-space: pre-wrap;
                overflow: scroll;
                background: none;
                margin: 0;
                padding: 0;
            }
        }

        table {
            width: 80%;
            margin: 40px auto;
            border-collapse: collapse;
            border-spacing: 0;
            display: table;

            tr:nth-child(even) {
                background-color: rgba(255, 255, 255, 0.1);
            }

            th, td {
                padding: 15px 20px;
                border: 1px solid rgba(255, 255, 255, 0.1);
            }
        }

        blockquote {
            border-left: 10px solid rgba(255, 255, 255, 0.1);

            p {
                margin: 0;
            }
        }

        ul, ol {
            list-style-position: inside;

            li {
                list-style-type:none;

                &:before {
                    content: '\2022';
                    font-size: 20px;
                    margin-right: 10px;
                }
            }
        }

        hr {
            border: 2px solid rgba(255, 255, 255, 0.1);
        }
    }

    .blog-listing {

        h2 {
            margin-top: 0;
        }

        .blog-item {

            padding: 30px 0;

            &:nth-child(odd) {
                background: rgba(255, 255, 255, 0.1);
            }

        }
    }
}

